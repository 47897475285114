import React from 'react'
import { chakra, Box, Button, Flex } from '@chakra-ui/react'
import { Link } from 'gatsby'

import { Container, Nav, MobileNav } from '../layout'
import Logo from '../elements/Logo'

const Header = () => {
  return (
    <Box
      as="header"
      bg="white"
      borderBottomColor="gray.200"
      borderBottomStyle="solid"
      borderBottomWidth={1}
      w={'full'}
      overflow="visible"
      position="relative"
      zIndex={100}
    >
      <Container>
        <Flex
          justify="space-between"
          position="relative"
          align="center"
          width="full"
          flexWrap="nowrap"
        >
          <Box width={[220, 240]}>
            <Link to="/" aria-label="Morgantown Airport Logo">
              <Logo color="black" />
            </Link>
          </Box>

          <Nav
            display={['none', 'none', 'flex']}
            style={{ marginLeft: 'auto' }}
          />

          <MobileNav style={{ marginLeft: 'auto' }} />
        </Flex>
      </Container>
    </Box>
  )
}

export default Header
