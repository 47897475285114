import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <script
            async
            src="https://tag.simpli.fi/sifitag/2c371c10-db04-0136-5558-067f653fa718"
          ></script>
        </Helmet>

        <Header />
        {children}
        <Footer />
        <script
            dangerouslySetInnerHTML={{
              __html: `var ssaUrl = 'https://' + 'pixel.sitescout.com/iap/a16cf5f559c1aa95';new Image().src = ssaUrl; (function(d) { var syncUrl = 'https://' + 'pixel.sitescout.com/dmp/asyncPixelSync'; var iframe = d.createElement('iframe'); (iframe.frameElement || iframe).style.cssText = "width: 0; height: 0; border: 0; display: none;"; iframe.src = "javascript:false"; d.body.appendChild(iframe); var doc = iframe.contentWindow.document; doc.open().write('<body onload="window.location.href=\''+syncUrl+'\'">'); doc.close(); })(document); `,
            }}
          />

        <script
            dangerouslySetInnerHTML={{
              __html: `var ssaUrl = 'https://' + 'pixel.sitescout.com/iap/5ccadd2c3aa6997f';new Image().src = ssaUrl; (function(d) { var syncUrl = 'https://' + 'pixel.sitescout.com/dmp/asyncPixelSync'; var iframe = d.createElement('iframe'); (iframe.frameElement || iframe).style.cssText = "width: 0; height: 0; border: 0; display: none;"; iframe.src = "javascript:false"; d.body.appendChild(iframe); var doc = iframe.contentWindow.document; doc.open().write('<body onload="window.location.href=\''+syncUrl+'\'">'); doc.close(); })(document); `,
            }}
          />

      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
