import React from 'react'
import { Box, Button, Flex, Slide, useDisclosure } from '@chakra-ui/react'
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'

import { Nav } from './'

const Toggle = props => {
  const { isOpen, onToggle } = useDisclosure()
  const color = 'black'

  return (
    <Box
      display={['inline-block', 'inline-block', 'none']}
      cursor="pointer"
      position="relative"
      {...props}
    >
      <Button
        onClick={onToggle}
        aria-label="Menu"
        bg="transparent"
        color={color}
        variant="link"
        zIndex={200}
        _hover={{
          background: 'transparent',
          border: 'none',
          outline: 'none',
        }}
        _focus={{
          background: 'transparent',
          border: 'none',
          outline: 'none',
        }}
      >
        {isOpen ? (
          <CloseIcon w={6} h={6} color="white" />
        ) : (
          <HamburgerIcon w={8} h={8} color={color} />
        )}
      </Button>

      <Slide in={isOpen} style={{ zIndex: 199 }}>
        <Box
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          height={`calc(100% + 100vh)`}
          width="100%"
          bgColor="darkgray"
          opacity="1"
          transition={{ opacity: '0.30s', visibility: '0.30s' }}
        >
          <Flex
            flexDirection="column"
            flexWrap="nowrap"
            padding="8em 1em 0 1em"
            width="100%"
          >
            <Nav
              fontSize="xl"
              lineHeight="10"
              textTransform="uppercase"
              sx={{ a: { color: 'white', textDecoration: 'none' } }}
            />
          </Flex>
        </Box>
      </Slide>
    </Box>
  )
}

export default Toggle
