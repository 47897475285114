import React from 'react'
import { Box } from '@chakra-ui/react'

const Container = props => {
  return (
    <Box mx="auto" maxWidth={1280} px={3} {...props}>
      {props.children}
    </Box>
  )
}

export default Container
