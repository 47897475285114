import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import {
  Box,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { FaFacebook, FaTwitter } from 'react-icons/fa'
import config from '../../../config/website'

import Container from './Container'
import LinkExt from '../elements/LinkExt'
import Logo from '../elements/Logo'

const Footer = props => {
  const d = new Date()
  const n = d.getFullYear()
  const data = useStaticQuery(graphql`
    query footerInfo {
      prismicGlobals {
        data {
          address {
            html
          }
          phone_number {
            text
          }
          site_name {
            text
          }
          facebook {
            text
          }
          twitter {
            text
          }
        }
      }
      allPrismicAirportPages {
        edges {
          node {
            data {
              title {
                text
              }
            }
            uid
            id
          }
        }
      }
    }
  `)

  return (
    <Box>
      <Container>
        <Flex flexWrap="wrap" justifyContent="space-between" py={12} mx={-6}>
          <Box width={[1, 1 / 3, 1 / 6]} px={6} mb={[6, 6, 0]}>
            <Logo />
          </Box>
          <Box width={['50%', 1 / 3, 1 / 6]} px={6} mb={[6, 6, 0]}>
            <Heading as="h6" fontSize="md" mb={6}>
              Airport
            </Heading>
            <UnorderedList
              listStyleType="none"
              p={0}
              m={0}
              lineHeight={1.15}
              sx={{
                a: {
                  fontSize: 'md',
                  textDecoration: 'none',
                  color: 'gray.700',
                },
                'a:hover': {
                  color: 'black',
                },
              }}
            >
              <ListItem>
                <Link to="/airport" activeClassName="active">
                  Overview
                </Link>
              </ListItem>
              {data.allPrismicAirportPages.edges.map(({ node: item }) => (
                <li key={item.id}>
                  <Link to={`/airport/${item.uid}`} activeClassName="active">
                    {item.data.title.text}
                  </Link>
                </li>
              ))}
            </UnorderedList>
          </Box>
          <Box width={['50%', 1 / 3, 1 / 6]} px={6} mb={[6, 6, 0]}>
            <Heading as="h6" fontSize="md" mb={6}>
              Flights
            </Heading>
            <UnorderedList
              listStyleType="none"
              p={0}
              m={0}
              lineHeight={1.15}
              sx={{
                a: {
                  fontSize: 'md',
                  textDecoration: 'none',
                  color: 'gray.700',
                },
                'a:hover': {
                  color: 'black',
                },
              }}
            >
              <ListItem>
                <Link to="/flights#intro">Southern Airways</Link>
              </ListItem>
              <ListItem>
                <Link to="/flights#schedule">Schedule</Link>
              </ListItem>
              <ListItem>
                <Link to="/flights#booking">Booking</Link>
              </ListItem>
            </UnorderedList>
          </Box>
          <Box width={['50%', 1 / 3, 1 / 6]} px={6} mb={[6, 6, 0]}>
            <Heading as="h6" fontSize="md" mb={6}>
              Resources
            </Heading>
            <UnorderedList
              listStyleType="none"
              p={0}
              m={0}
              lineHeight={1.15}
              sx={{
                a: {
                  fontSize: 'md',
                  textDecoration: 'none',
                  color: 'gray.700',
                },
                'a:hover': {
                  color: 'black',
                },
              }}
            >
              <ListItem>
                <Link to="/resources#intro">Overview</Link>
              </ListItem>
              <ListItem>
                <Link to="/resources#faqs">FAQs</Link>
              </ListItem>
            </UnorderedList>
          </Box>
          <Box width={['50%', 1 / 3, 1 / 6]} px={6} mb={[6, 6, 0]}>
            <Heading as="h6" fontSize="md" mb={6}>
              In the loop
            </Heading>
            <UnorderedList
              listStyleType="none"
              p={0}
              m={0}
              lineHeight={1.15}
              sx={{
                a: {
                  fontSize: 'md',
                  textDecoration: 'none',
                  color: 'gray.700',
                },
                'a:hover': {
                  color: 'black',
                },
              }}
            >
              <ListItem>
                <Link to="/events">Events</Link>
              </ListItem>
              <ListItem>
                <Link to="/insights">Insights</Link>
              </ListItem>
            </UnorderedList>
          </Box>
          <Box width={['50%', 1 / 3, 1 / 6]} px={6} mb={[6, 6, 0]}>
            <Heading as="h6" fontSize="md" mb={6}>
              Contact
            </Heading>
            <UnorderedList
              listStyleType="none"
              p={0}
              m={0}
              lineHeight={1.15}
              sx={{
                a: {
                  fontSize: 'md',
                  textDecoration: 'none',
                  color: 'gray.700',
                },
                'a:hover': {
                  color: 'black',
                },
              }}
            >
              <ListItem>
                <Link to="/contact#directory">Directory</Link>
              </ListItem>
              <ListItem>
                <Link to="/contact#directions">Directions</Link>
              </ListItem>
            </UnorderedList>

            <Flex flexWrap="nowrap" fontSize="2xl" mt={6}>
              <LinkExt to={data.prismicGlobals.data.facebook.text} mr={6}>
                <FaFacebook className="icons" />
              </LinkExt>
              <LinkExt to={data.prismicGlobals.data.twitter.text}>
                <FaTwitter className="icons" />
              </LinkExt>
            </Flex>
          </Box>
        </Flex>
        <Box
          width={'full'}
          py={4}
          borderTopColor="gray.100"
          borderTopWidth={1}
          borderTopStyle="solid"
        >
          <Text textAlign="center" fontSize="sm" color="gray.600">
            &copy; {n} Copyright - {config.siteTitle}, Website Design &
            Development:{' '}
            <LinkExt to="https://www.mindmergedesign.com">
              Mind Merge Design
            </LinkExt>
          </Text>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
