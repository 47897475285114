import React from 'react'
import { Box } from '@chakra-ui/react'

const Section = props => {
  return (
    <Box as="section" py={[12, 14]} {...props}>
      {props.children}
    </Box>
  )
}

export default Section
