module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Morgantown Municipal Airport', // Navigation and Site Title
  siteTitleAlt: 'MGW', // Alternative Site title for SEO
  siteShortName: 'MGW', // Short name
  siteUrl: 'https://www.morgantownairport.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '../src/images/favicon.png', // Used for SEO and manifest
  siteDescription: 'Morgantown Municipal Airport',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // userTwitter: '@user', // Twitter Username
  // ogSiteName: 'mmd', // Facebook Site Name
  // ogLanguage: 'en_US', // Facebook Language

  // Manifest and Progress color
  themeColor: '#4299e1',
  backgroundColor: '#4299e1',
}
